<template>
  <CRow>
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
              {{$t('tasks.Tasks')}}
            </CCol>
            <CCol cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showCompleted" size="is-small" @input="getTasks()">{{$t('tasks.Show_completed_tasks')}}</b-switch>
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="ml-1 link" color="primary" @click="newTaskModal = true; resetTaskData();">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{$t('tasks.Add_task')}}</span>
                </CButton>                
              </div>              
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" class="pb-0 pt-0">
              <b-table class="data_table" :data="tasks" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                <template slot-scope="props">
                  <b-table-column field="task_title" :label="$t('tasks.Task')" width="55%">
                    <div v-if="props.row.task_done === 1">
                      <span class="mr-2">{{props.row.task_title}}</span>
                      <CBadge v-if="props.row.tied_to !== null && props.row.task_type === 'Team'" v-c-tooltip.hover="{placement: 'left', content: $t('common.Tied_to') + ' ' + props.row.tied_to}" color="success">{{props.row.tied_to}}</CBadge>
                      <CBadge v-if="props.row.tied_to !== null && props.row.task_type === 'Pillar'" v-c-tooltip.hover="{placement: 'left', content: $t('common.Tied_to') + ' ' + $t('sq.pillar') + ' ' + props.row.tied_to}" color="primary">{{props.row.tied_to}}</CBadge>
                      <CBadge v-if="props.row.task_type === 'Personal'" color="info">{{$t('tasks.Personal_task')}}</CBadge>                      
                    </div>
                    <div v-else>
                      <quick-edit type="text" mode="ignore" :buttonOkText="$t('Update')" :buttonCancelText="$t('cancel')" v-model="props.row.task_title" v-on:input="updateTask(props.row.task_id, props.row)">{{props.row.task_title}}
                        <i class="icon edit fas fa-pen ml-1 mr-1"/>                        
                        <CBadge v-if="props.row.tied_to !== null && props.row.task_type === 'Team'" v-c-tooltip.hover="{placement: 'left', content: $t('common.Tied_to') + ' ' + props.row.tied_to}" color="success">{{props.row.tied_to}}</CBadge>
                        <CBadge v-if="props.row.tied_to !== null && props.row.task_type === 'Pillar'" v-c-tooltip.hover="{placement: 'left', content: $t('common.Tied_to') + ' ' + $t('sq.pillar') + ' ' + props.row.tied_to}" color="primary">{{props.row.tied_to}}</CBadge>
                        <CBadge v-if="props.row.task_type === 'Personal'" color="info">{{$t('tasks.Personal_task')}}</CBadge>
                      </quick-edit>
                    </div>                    
                  </b-table-column>
                  <!-- <b-table-column field="task_type" :label="$t('common.Group')" width="10%">
                    {{props.row.task_type}}
                  </b-table-column> -->
                  <!-- <b-table-column field="tied_to" :label="$t('common.Tied_to')" width="15%">
                    {{props.row.tied_to !== null ? props.row.tied_to : "-"}}
                  </b-table-column> -->
                  <b-table-column field="created" :label="$t('common.Added')" width="15%">
                    {{props.row.created}}
                  </b-table-column>
                  <b-table-column field="marked_done" :label="$t('common.Completed')" width="15%">
                    {{props.row.marked_done !== null ? props.row.marked_done : "-"}}
                  </b-table-column>
                  <b-table-column field="mark_done" width="15%">                                        
                    <b-checkbox v-model="props.row.task_done" true-value="1" false-value="0" :disabled="props.row.task_done === 1" @input="confirmModal = true; confirmModalData = props.row;">
                      {{props.row.task_done === 1 ? $t('common.Completed') : $t('common.Complete')}}
                    </b-checkbox>
                  </b-table-column>                  
                </template>
                <template slot="empty">
                  <div class="p-2 text-center">
                    <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="$t('tasks.Loading_tasks')"/>
                    <span v-else>{{$t('tasks.No_tasks_found')}}</span>
                  </div>
                </template>                
              </b-table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>      

      <b-modal class="tasks" :can-cancel="['x']" :active.sync="newTaskModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            {{$t('tasks.New_task')}}
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol cols="6" lg="6" class="pt-0">
                <label>{{$t('tasks.Task_intended_for')}}</label>          
                <CButtonGroup class="w-100">
                  <CButton color="outline-secondary" v-for="(value, name, key) in [$t('tasks.Personal'), $t('tasks.Teams')]" :key="key" :pressed="value === newTask.task_type ? true : false" @click="newTask.task_type = value; newTask.teams = []">
                    {{value}}
                  </CButton>
                </CButtonGroup>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0" v-if="newTask.task_type === $t('tasks.Teams')">
                <label>{{$t('tasks.Which_teams')}}</label>
                <multiselect
                  class="data_table"
                  v-model="newTask.teams" 
                  :options="departments" 
                  :multiple="true"
                  :group-select="true"
                  group-values="teams" 
                  group-label="department_name"            
                  :placeholder="$t('Search_for_a') + ' ' + $t('common.team')" 
                  :selectLabel="$t('common.Add_team')" 
                  :selectGroupLabel="$t('common.Add_department')" 
                  :selectedLabel="$t('Added')"
                  :deselectLabel="$t('common.Remove_team')"
                  :deselectGroupLabel="$t('common.Remove_department')"                  
                  track-by="team_name" 
                  label="team_name">
                  <span slot="noResult">{{$t('common.no_teams_found')}}</span>
                </multiselect>
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">                
                <CInput class="mb-0" type="text" :label="$t('tasks.Task_name')" v-model="newTask.task_title" required was-validated/>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <div>
              <CButton color="primary" @click="insertTask()"><i class="fas fa-save mr-1"/>{{$t('Save')}}</CButton>
              <CButton color="secondary" @click="newTaskModal = false"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
            </div>
          </CCardFooter>          
        </CCard>
      </b-modal>

      <b-modal class="tasks" :can-cancel="[]" :active.sync="confirmModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">            
            {{!showTimedMomentOption ? $t('tasks.Confirm_completion_task') : $t('timed_moments.New_timed_moment')}}
          </CCardHeader>
          <CCardBody>
            <CRow v-if="!showTimedMomentOption">          
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <p class="mb-0">{{$t('tasks.Are_you_sure')}} {{confirmModalData.task_title}}? {{$t('tasks.Completion_cannot_be_undone')}}</p>
              </CCol>
            </CRow>
            <CRow v-else>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <p class="mb-0">{{$t('tasks.Set_completion_as')}} {{confirmModalData.task_title}} {{$t('tasks.as_a_timed_moment')}} {{$t('tasks.Fill_in_information')}}</p>
                <CRow>
                  <CCol cols="12" lg="12" class="pb-0">                
                    <CSelect class="mb-0" :label="$t('timed_moments.Which_type')" :value.sync="newTimedMoment.tm_type_id" :options="types" :placeholder="$t('timed_moments.Select_a_type')" required was-validated/>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" class="pb-0">                
                    <label class="w-100">{{$t('timed_moments.Description_timed_moment')}}<span class="countdown" v-if="timedMomentRemainingCount > 0">{{timedMomentRemainingCount}} {{$t('common.characters_remaining')}}</span></label>
                    <CTextarea class="mb-0" @input="timedMomentCountdown()" v-model="newTimedMoment.description" rows="3" :maxlength="timedMomentMaxCount" required was-validated/>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <div v-if="!showTimedMomentOption && confirmModalData.task_type_id !== 2">
              <CButton color="primary" @click="updateTaskStatus(confirmModalData.task_id, confirmModalData.task_done)"><i class="fas fa-check mr-1"/>{{$t('tasks.Confirm_completion_task')}}</CButton>                            
              <CButton color="secondary" @click="confirmModal = false; getTasks()"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
            </div>
            <div v-else-if="!showTimedMomentOption && confirmModalData.task_type_id === 2">
              <!-- <CButton color="primary" @click="showTimedMomentOption = true;"><i class="fas fa-check mr-1"/>{{$t('tasks.Confirm_completion_task')}}</CButton> -->
              <CButton color="primary" @click="addTimedMoment = false; updateTaskStatus(confirmModalData.task_id, confirmModalData.task_done)"><i class="fas fa-check mr-1"/>{{$t('tasks.Confirm_completion_task')}}</CButton>  
              <CButton color="secondary" @click="confirmModal = false; showTimedMomentOption = false; getTasks()"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="addTimedMoment = true; updateTaskStatus(confirmModalData.task_id, confirmModalData.task_done);"><i class="fas fa-plus mr-1"/>{{$t('timed_moments.Create_timed_moment')}}</CButton>
              <CButton color="secondary" @click="addTimedMoment = false; updateTaskStatus(confirmModalData.task_id, confirmModalData.task_done);"><i class="fas fa-times mr-1"/>{{$t('timed_moments.Complete_without_timed_moment')}}</CButton>
            </div>                  
          </CCardFooter>
        </CCard>
      </b-modal>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect'
import loadingSpinner from '@/components/common/loadingSpinner.vue'

export default {
  name: 'Rewards',
  components: {
    QuickEdit,
    Multiselect,
    loadingSpinner
  },
  data() {
    return {
      tasks: [],
      overviewDataLoaded: false,
      departments: [],
      types: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      newTask: {},
      newTaskModal: false,
      confirmModal: false,
      confirmModalData: {},
      newTimedMoment: {},
      addTimedMoment: false,      
      showTimedMomentOption: false,      
      taskMaxCount: 1000,
      taskRemainingCount: 1000,
      timedMomentMaxCount: 250,
      timedMomentRemainingCount: 250,
      showCompleted: false
    }
  },
  methods: {
    insertTask () {
      let params = {};
      params.task_title = this.newTask.task_title;
      params.task_description = this.newTask.task_description;
      params.teams = this.newTask.teams;

      if(this.newTask.task_type === this.$t('tasks.Personal')) {
        params.task_type_id = 1;          
      } else if(this.newTask.task_type === this.$t('tasks.Teams')) {
        params.task_type_id = 2;
      }

      let task_title = params.task_title;

      if(task_title) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/hub/tasks', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('tasks.Task_added'), type: 'is-success', duration: 2000 });
          this.getTasks();
          this.resetTaskData();
          this.newTaskModal = false;
        })
        .catch(err => {
          console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    getTasks () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/tasks')
      .then(res => {
        this.tasks = res.data.data;
        this.overviewDataLoaded = true;
        // If completed tasks must be hidden, only show tasks where task_done value is 0
        if(!this.showCompleted) {
          this.tasks = this.tasks.filter( i => [0].includes( i.task_done ) );
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateTask (id, data) {
      let taskId = id;
      let params = {};
      
      params.task_title = data.task_title;
      params.task_description = data.task_description;

      axios.put(process.env.VUE_APP_API_URL + '/v1/hub/task/' + taskId, params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('tasks.Task_updated'), type: 'is-success', duration: 2000 });        
        this.getTasks();
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    updateTaskStatus (id, value) {
      let taskId = id;
      let params = {};      
      params.task_done = value;

      if(!this.addTimedMoment) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/hub/task/' + taskId + '/status', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('tasks.Task_completed'), type: 'is-success', duration: 2000 });       
          this.confirmModal = false;
          this.showTimedMomentOption = false;
          this.addTimedMoment = false;
          this.getTasks();        
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        let tmParams = {};
        tmParams.description = this.newTimedMoment.description;
        tmParams.tm_group_id = 3;
        tmParams.group_id = this.confirmModalData.type_id;
        tmParams.tm_type_id = this.newTimedMoment.tm_type_id;

        let tm_group_id = tmParams.tm_group_id;
        let tm_type_id = tmParams.tm_type_id;
        let description = tmParams.description; 

        if(tm_group_id && tm_type_id && description) {
          axios.put(process.env.VUE_APP_API_URL + '/v1/hub/task/' + taskId + '/status', params)
          .then(res => {
            this.$buefy.toast.open({ message: this.$t('tasks.Task_completed'), type: 'is-success', duration: 2000 });         
          })
          .catch(err => {
            console.error(err); 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          });

          axios.post(process.env.VUE_APP_API_URL + '/v1/core/timed_moment', tmParams)
          .then(res => {
            this.$buefy.toast.open({ message: this.$t('timed_moments.Timed_moment_added'), type: 'is-success', duration: 2000 });
            this.resetTimedMomentData();
          })
          .catch(err => {
            console.error(err); 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          })

          this.confirmModal = false;          
          this.showTimedMomentOption = false;
          this.addTimedMoment = false;
          this.getTasks();

        } else {
          this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        }
      }
    },            
    resetTaskData () {  
      this.newTask = {
        task_type: this.$t('tasks.Personal'),
        task_title: null,
        task_description: null,
        teams: []
      };
      this.taskRemainingCount = 1000;
    },    
    resetTimedMomentData () {  
      this.newTimedMoment = {
        tm_group_id: null,        
        group_id: null,
        tm_type_id: null,
        description: null
      };
      this.timedMomentRemainingCount = 250;
    },
    taskCountdown () {
      this.taskRemainingCount = this.taskMaxCount - this.newTask.task_description.length;
    },
    timedMomentCountdown () {
      this.timedMomentRemainingCount = this.timedMomentMaxCount - this.newTimedMoment.description.length;
    }
  },
  mounted: function(){
    this.getTasks();
    this.resetTaskData();
    this.resetTimedMomentData();

    axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departmentTeams/my')
      .then(res => {
        // Get management result from API response                
        var departmentTeams = res.data.data;
        // Empty departments array
        this.departments = [];        
        // Loop through management result and add unique departments to departments array
        var lookup = {};
        for (var departmentTeam, index = 0; departmentTeam = departmentTeams[index++];) {
          var departmentId = departmentTeam.department_id;

          if (!(departmentId in lookup)) {
            lookup[departmentId] = 1;
            this.departments.push({
              department_id: departmentTeam.department_id,
              department_name: departmentTeam.department_name,
              teams: []
            });
          }
        }

        for(var d = 0; d < this.departments.length; d++) {
          // Loop through management result
          for(var t = 0; t < departmentTeams.length; t++) {
            // Check if a management result is part of a department
            if(this.departments[d].department_id === departmentTeams[t].department_id) {
              // If so, add the management result data into the teams array of this department
              this.departments[d].teams.push({
                team_id: departmentTeams[t].team_id,
                team_name: departmentTeams[t].team_name,
              });
            }
          }
        }
      })
      .catch(err => {
        console.error(err); 
    });
    
    axios.get(process.env.VUE_APP_API_URL + '/v1/core/timed_moments/types')
    .then(res => {
      this.types = res.data.data;
    })
    .catch(err => {
      console.error(err); 
    });    
  }
}
</script>